import { render, staticRenderFns } from "./MenuLinkList.vue?vue&type=template&id=247976a8&scoped=true&"
import script from "./MenuLinkList.vue?vue&type=script&lang=js&"
export * from "./MenuLinkList.vue?vue&type=script&lang=js&"
import style0 from "./MenuLinkList.vue?vue&type=style&index=0&id=247976a8&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "247976a8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MenuLinkItem: require('/builds/reifen_gundlach/frontend/components/menu/MenuLinkItem/MenuLinkItem.vue').default})
